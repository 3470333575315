import { inject } from 'vue';

export const PENDO_KEY = 'pendo';

const whenAvailable = (name, callback) => {
    const interval = 200;
    let attempts = 0;

    const handle = window.setInterval(() => {
        if (attempts > 20) {
            clearInterval(handle);
        } else if (window[name]) {
            clearInterval(handle);
            callback(window[name]);
        }

        attempts++;
    }, interval);
};

export const createPendo = ({ apiKey, enabled }) => {
    const attachScripts = () => {
        !(function(e, n, t, i, a) {
            let c; let o; let d; let s; let p;

            // eslint-disable-next-line
            for ((a = e[i] = e[i] || {})._q = [], o = 0, d = (c = ['initialize', 'identify', 'updateOptions', 'pageLoad']).length; o < d; ++o)!(function(e) { a[e] = a[e] || function() { a._q[e === c[0] ? 'unshift' : 'push']([e].concat([].slice.call(arguments, 0))); }; }(c[o])); (s = n.createElement(t)).async = !0, s.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js', (p = n.getElementsByTagName(t)[0]).parentNode.insertBefore(s, p);
        }(window, document, 'script', 'pendo'));
    };

    const initialize = ({
        userType,
        email,
        casId,
        partner,
        buildNumber,
        appName,
        blockInfoRole,
        givenName,
        familyName,
    }) => {
        whenAvailable('pendo', (pendo) => {
            pendo.TOOLTIP_ARROW_SIZE = 8;
            pendo.initialize({
                apiKey,

                usePendoAgentAPI: false,

                visitor: {
                    id: casId,
                    email,
                    firstName: givenName,
                    lastName: familyName,
                    userType,
                    isPartner: partner,
                },

                account: {
                    id: appName,
                    block: blockInfoRole,
                    appVersion: buildNumber,
                },
            });
        });
    };

    const track = (eventName, eventProperties) => {
        whenAvailable('pendo', (pendo) => {
            pendo.track(eventName, eventProperties);
        });
    };

    const pendo = {
        attachScripts: enabled ? attachScripts : () => {},
        initialize: enabled ? initialize : () => {},
        track: enabled ? track : () => {},
        install(app) {
            app.provide(PENDO_KEY, pendo);
        },
    };

    return pendo;
};

export function usePendo() {
    return inject(PENDO_KEY);
}
