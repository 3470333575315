import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useInstallQueries } from '../../install/api/install';

export const useInstallRequestStore = defineStore('installRequest', () => {
    const dependencyList = ref([]);
    const installItem = ref({});
    const partnerInfo = ref({});
    const queries = useInstallQueries();

    function reset() {
        dependencyList.value = [];
        installItem.value = {};
        partnerInfo.value = {};
    }

    async function fetchFromLink(linkId, { showOwnerType }) {
        const { data: { linkInfo } } = await queries.getLinkInfo(linkId, { showOwnerType });

        dependencyList.value = linkInfo.dependencies || [];
        partnerInfo.value = linkInfo.company;
        installItem.value = {
            ...linkInfo,
            company: undefined,
        };
    }

    async function fetchFromRequest(requestId) {
        const { data: { installRequest } } = await queries.getRequestInfo(requestId);

        partnerInfo.value = installRequest.company;
        dependencyList.value = installRequest.dependencies || [];
        installItem.value = {
            ...installRequest,
            company: undefined,
            dependencies: undefined,
        };
    }

    return {
        dependencyList,
        installItem,
        partnerInfo,

        reset,
        fetchFromLink,
        fetchFromRequest,
    };
});
