import { defineStore } from 'pinia';
import { loadSettings } from './api';
import {
    IDLE, LOADING, SUCCESS, ERROR,
} from '@/constants/loadingStatuses.constants';
import { OWNER_TYPES } from '@keap-web/content-sharing-lib';

export const useTenantSettingsStore = defineStore('tenantSettings', {
    state: () => ({
        loadingStatus: IDLE,
        settings: {},
    }),

    actions: {
        async loadSettings(appId) {
            try {
                this.loadingStatus = LOADING;
                const data = await loadSettings(appId);

                this.settings = data;
                this.loadingStatus = SUCCESS;
            } catch {
                // this will throw a 404 most of the time
                this.loadingStatus = ERROR;
            }
        },

        reset() {
            this.$reset();
        },
    },

    getters: {
        canUseInternalCartId() {
            return this.isInfluencerOwnerType || this.isKeapOwnerType;
        },

        canEnableResharing() {
            return this.isInfluencerOwnerType || this.isKeapOwnerType;
        },

        isInfluencerOwnerType({ settings }) {
            return [OWNER_TYPES.INFLUENCER].includes(settings?.ownerType);
        },

        isKeapOwnerType({ settings }) {
            return [OWNER_TYPES.KEAP].includes(settings?.ownerType);
        },
    },
});
