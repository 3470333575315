import { ACTIVE_UI } from '@keap-web/content-sharing-lib';

export const formatUserAccount = (account) => {
    const {
        account: {
            appUrl,
            tenantId,
            companyProfile: { name: companyName, logoUrl },
            edition,
            activeUserInterface,
        },
        status,
    } = account;

    let target;
    let installContentHref;
    let installRequestHref;

    switch (activeUserInterface) {
    case ACTIVE_UI.WEB:
        installContentHref = `${window.__KEAP__.VUE_APP_KEAP_URL}/install-content/{linkId}?app_id=${tenantId}&ticket={jwt}`;
        installRequestHref = `${window.__KEAP__.VUE_APP_KEAP_URL}/install-request/{requestId}?app_id=${tenantId}&ticket={jwt}`;
        target = `keap-web-${window.__KEAP__.VUE_APP_ENV}`;
        break;
    case ACTIVE_UI.ULTIMATE:
        installContentHref = `${window.__KEAP__.VUE_APP_KEAP_ULTIMATE_URL}/install-content/{linkId}?app_id=${tenantId}&ticket={jwt}`;
        installRequestHref = `${window.__KEAP__.VUE_APP_KEAP_ULTIMATE_URL}/install-request/{requestId}?app_id=${tenantId}&ticket={jwt}`;
        target = `keap-web-${window.__KEAP__.VUE_APP_ENV}`;
        break;
    default:
        target = `keap-${tenantId}-${window.__KEAP__.VUE_APP_ENV}`;
        break;
    }

    return {
        tenantId,
        companyName,
        logoUrl,
        edition,
        href: appUrl,
        installContentHref,
        installRequestHref,
        activeUserInterface,
        target,
        status,
    };
};
