import axios from 'axios';
import { formatUserAccount } from './transformer';
import { ACTIVE_UI } from '@keap-web/content-sharing-lib';

export const loadAccounts = async (userId, {
    pageSize = 50,
    searchText = '',
    orderBy = ['account.tenantId:ASC'],
    sandboxOnly = false,
} = {}) => {
    const params = new URLSearchParams({
        readMask: ['account.appUrl', 'account.tenantId', 'account.companyProfile.name', 'account.companyProfile.logoUrl', 'account.edition', 'account.activeUserInterface', 'status'],
        statuses: 'Active',
        pageSize,
        userInterfaces: [ACTIVE_UI.WEB, ACTIVE_UI.ULTIMATE, ACTIVE_UI.CLASSIC],
        sandboxOnly,
    });

    if (searchText) {
        params.append('filter', searchText);
    }

    orderBy.forEach((sort) => {
        params.append('orderBy', sort);
    });

    const url = `${window.__KEAP__.VUE_APP_ACCOUNT_SERVICE_URL}/api/v3/users/${userId}/accounts?${params.toString()}`;
    const { data } = await axios.get(url);

    if (data?.userAccounts) {
        const { userAccounts } = data;

        return userAccounts?.map(formatUserAccount) || [];
    }

    return [];
};

export const loadPartnerGroups = async ({ userId, tenantId }) => {
    const queryParams = new URLSearchParams();

    queryParams.append('readMask', 'group.partnerTenantId');
    queryParams.append('readMask', 'group.id');
    const url = `${window.__KEAP__.VUE_APP_ACCOUNT_SERVICE_URL}/api/v3/users/${userId}/partnerGroups?${queryParams.toString()}`;
    const { data } = await axios.get(url);

    // TODO use backend to filter
    const userGroups = data.userGroups.filter((userGroup) => userGroup.group.partnerTenantId === tenantId);

    return { userGroups };
};

export const createPartnerGroup = async ({ tenantId, title }) => {
    const url = `${window.__KEAP__.VUE_APP_ACCOUNT_SERVICE_URL}/api/v3/partnerGroups`;
    const { data } = await axios.post(url, {
        partnerTenantId: tenantId,
        title,
        createdById: 'current',
    });

    return data;
};

export const addUserToPartnerGroup = async ({ partnerGroupId, userId }) => {
    const url = `${window.__KEAP__.VUE_APP_ACCOUNT_SERVICE_URL}/api/v3/partnerGroups/${partnerGroupId}/users`;
    const { data } = await axios.post(url, {
        user: {
            id: userId,
        },
        createdById: 'current',
    });

    return data;
};
