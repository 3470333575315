import { defineStore } from 'pinia';
import {
    createLinkInstallRequest, createInstallRequest, installRequestStatus, loadRequestErrors,
} from '../../install/api';
import { REQUEST_STATUSES, MAX_INSTALL_TIMEOUT } from '../../constants/share.constants';
import { useShareUrlsStore } from '../shareUrls';

const {
    IN_PROGRESS, NEEDS_RETRIED, INSTALLING, BUILDING_DEPENDENCIES, LOADING_DEPENDENCIES, QUEUED, FAILED, COMPLETED,
} = REQUEST_STATUSES;
const inProgressStatuses = [IN_PROGRESS, NEEDS_RETRIED, INSTALLING, BUILDING_DEPENDENCIES, LOADING_DEPENDENCIES, QUEUED];

export const useInstallStore = defineStore('install', {
    state: () => ({
        installApps: [],
        expandedTypes: [],
    }),

    getters: {
        isInstallComplete({ installApps }) {
            return Boolean(installApps.length
            && installApps.some(({ status }) => [COMPLETED].includes(status))
            && installApps.every(({ status }) => [COMPLETED, FAILED].includes(status)));
        },

        isInstalling({ installApps }) {
            return installApps.some(({ status }) => inProgressStatuses.includes(status));
        },

        isInstallingStatus: () => (status) => {
            return inProgressStatuses.includes(status);
        },

        isInstallFailed({ installApps }) {
            return Boolean(installApps.length && installApps.every(({ status }) => [FAILED].includes(status)));
        },

        installSuccessApps({ installApps }) {
            return installApps.filter(({ status }) => status === COMPLETED);
        },

        installFailedApps({ installApps }) {
            return installApps.filter(({ status }) => [FAILED].includes(status));
        },
    },

    actions: {
        addExpandedType(type) {
            this.expandedTypes.push(type);
        },

        removeExpandedType(type) {
            const index = this.expandedTypes.indexOf(type);

            if (index > -1) {
                this.expandedTypes.splice(index, 1);
            }
        },

        install({ linkId, sharedResourceId }) {
            for (let i = 0; i < this.installApps.length; i++) {
                this.installOnApp({ app: this.installApps[i], linkId, sharedResourceId });
            }
        },

        setAppRequest({ app, requestId }) {
            app.requestId = requestId;
            this.installApps = [app];

            this.fetchInstallStatus(app);
        },

        async installOnApp({ app, linkId, sharedResourceId }) {
            try {
                let response;
                const shareUrls = useShareUrlsStore();

                if (linkId) {
                    response = await createLinkInstallRequest({ tenantId: app.tenantId, linkId }, shareUrls.packagingServiceUrl);
                } else {
                    response = await createInstallRequest({ tenantId: app.tenantId, sharedResourceId }, shareUrls.packagingServiceUrl);
                }

                const { requestId, status, failureReasons } = response;

                this.updateInstallApp({
                    ...app,
                    requestId,
                    status,
                    failureReasons,
                    startTime: Date.now(),
                });
            } catch (e) {
                this.updateInstallApp({ ...app, status: FAILED });
            }
        },

        fetchInstallStatuses() {
            this.installApps
                .filter(({ status, requestId, apiRequestStatus }) => requestId && apiRequestStatus !== IN_PROGRESS && inProgressStatuses.includes(status))
                .forEach((app) => {
                    this.fetchInstallStatus(app);
                });
        },

        async fetchInstallStatus(app) {
            try {
                this.updateInstallApp({ ...app, apiRequestStatus: IN_PROGRESS });
                const shareUrls = useShareUrlsStore();
                const {
                    status, failureReasons, updateTime, createTime,
                } = await installRequestStatus({ requestId: app.requestId }, shareUrls.packagingServiceUrl);
                const startTime = app.startTime || new Date(createTime).getTime();

                const updatePayload = {
                    ...app,
                    startTime,
                    timeout: Boolean(startTime && (Date.now() - startTime) > MAX_INSTALL_TIMEOUT),
                    status,
                    failureReasons,
                    apiRequestUpdateTime: updateTime,
                    apiRequestStatus: undefined,
                };

                if (status === REQUEST_STATUSES.FAILED) {
                    await this.fetchRequestErrors(app, updatePayload);
                } else {
                    this.updateInstallApp(updatePayload);
                }
            } catch (e) {
                this.updateInstallApp({
                    ...app, apiRequestStatus: undefined, apiRequestUpdateTime: new Date(), status: FAILED,
                });
            }
        },

        queueInstallApps(apps) {
            this.installApps = apps.map(({ companyName, tenantId, href }) => {
                return {
                    status: QUEUED,
                    tenantId,
                    companyName,
                    href,
                };
            });
        },

        resetInstallAppStatus() {
            this.installApps = this.installApps.map((app) => ({ ...app, status: '', failureReasons: [] }));
        },

        updateInstallApp(app) {
            const updateIndex = this.installApps.findIndex(({ tenantId }) => app.tenantId === tenantId);

            if (updateIndex > -1) {
                this.installApps.splice(updateIndex, 1, app);
            }
        },

        async fetchRequestErrors(app, payload) {
            const shareUrls = useShareUrlsStore();

            try {
                const { failures } = await loadRequestErrors({ requestId: app.requestId }, shareUrls.packagingServiceUrl);

                this.updateInstallApp({
                    ...app, ...payload, failureReasons: failures,
                });
            } catch (e) {
                this.updateInstallApp({
                    ...app, ...payload,
                });
            }
        },

        reset() {
            this.installApps = [];
            this.expandedTypes = [];
        },
    },
});
