import axios from 'axios';
import { REQUEST_STATUSES } from '@keap-web/content-sharing-lib';

const {
    QUEUED, IN_PROGRESS, BUILDING_DEPENDENCIES, EXPORTING, LOADING_DEPENDENCIES, INSTALLING, NEEDS_RETRIED,
} = REQUEST_STATUSES;

export const loadIsAuthorized = async (tenantId) => {
    const { data } = await axios.get(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/tenants/${tenantId}`);

    return data?.authorized;
};

export const loadSharedResources = async ({
    pageSize, pageToken, tenantId, sort, search, resourceTypes, resourceSubtypes,
}) => {
    const params = { tenantId, pageSize };

    if (sort) {
        params.orderBy = sort;
    }

    if (search) {
        params.filter = search;
    }

    if (pageToken) {
        params.pageToken = pageToken;
    } else {
        params.includeTotalSize = true;
    }

    if (resourceSubtypes?.length) {
        params.resourceSubtypes = resourceSubtypes;
    }

    if (resourceTypes?.length) {
        params.resourceTypes = resourceTypes;
    }

    params.exportedOnly = true;
    params.topLevelOnly = true;

    const queryParams = new URLSearchParams(params);

    const { data } = await axios.get(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/sharedResources?${queryParams.toString()}`);

    return data;
};

export const loadShareableResources = async ({ type }, {
    pageSize, pageToken, tenantId, owningTenantId, search, orderBy, subtype,
}) => {
    const params = { tenantId, owningTenantId, pageSize };

    if (pageToken) {
        params.pageToken = pageToken;
    } else {
        params.includeTotalSize = true;
    }

    if (search) {
        params.filter = search;
    }

    if (subtype) {
        params.resourceSubtype = subtype;
    }

    const queryParams = new URLSearchParams(params);

    if (!orderBy && !orderBy?.length) {
        orderBy = search ? ['title:ASC', 'updateTime:DESC'] : ['updateTime:DESC', 'title:ASC'];
    }

    orderBy?.forEach((sort) => {
        queryParams.append('orderBy', sort);
    });

    const { data } = await axios.get(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/shareableResources/type/${type}?${queryParams.toString()}`);

    return data;
};

export const loadRequestErrors = async ({ requestId, pageSize, pageToken }) => {
    const params = { pageSize: pageSize || 10, lastFailureOnly: true };

    if (pageToken) {
        params.pageToken = pageToken;
    } else {
        params.includeTotalSize = true;
    }

    const queryParams = new URLSearchParams(params);

    const { data } = await axios.get(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/requests/${requestId}/failures?${queryParams.toString()}`);

    return data;
};

export const loadActiveRequests = async ({ pageSize, tenantId }) => {
    const params = { pageSize: pageSize || 20, requestTenantId: tenantId };

    params.statuses = [QUEUED, IN_PROGRESS, BUILDING_DEPENDENCIES, EXPORTING, LOADING_DEPENDENCIES, INSTALLING, NEEDS_RETRIED];

    const queryParams = new URLSearchParams(params);

    const { data } = await axios.get(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/requests?${queryParams.toString()}`);

    return data;
};

export const getBundle = async (id) => {
    const { data } = await axios.get(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/bundles/${id}`);

    return data;
};

export const refreshBundle = async (id) => {
    const { data } = await axios.post(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/bundles/${id}:refresh`);

    return data;
};

export const updateBundle = async ({
    title, description, bundleId, topLevel, dependencies,
}) => {
    const queryParams = new URLSearchParams();
    const payload = {};

    if (title !== undefined) {
        queryParams.append('updateMask', 'title');
        payload.title = title;
    }

    if (description !== undefined) {
        queryParams.append('updateMask', 'description');
        payload.description = description;
    }

    if (topLevel !== undefined) {
        queryParams.append('updateMask', 'topLevel');
        payload.topLevel = topLevel;
    }

    if (dependencies !== undefined) {
        queryParams.append('updateMask', 'dependencies');
        payload.dependencies = dependencies;
    }

    const { data } = await axios.patch(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/bundles/${bundleId}?${queryParams}`, payload);

    return data;
};

export const getSharedResource = async (id) => {
    const { data } = await axios.get(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/sharedResources/${id}`);

    return data;
};

export const shareResource = async ({
    resourceType, resourceId, tenantId, title, sourceTenantId, publicDomain,
}) => {
    const { data } = await axios.post(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/sharedResources`, {
        tenantId,
        resourceType,
        sourceTenantId,
        sourceResourceId: resourceId,
        createdById: 'current',
        title,
        topLevel: true,
        publicDomain,
    });

    return data;
};

export const createExportRequest = async ({ sharedResourceId }, options = {}) => {
    const { data } = await axios.post(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/exportRequests`, {
        sharedResource: { sharedResourceId },
        useExistingDependencies: options.useExistingDependencies || false,
        createdById: 'current',
    });

    return data;
};

export const validateExport = async ({ sharedResourceId }, options = {}) => {
    const { data } = await axios.post(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/exportRequests:validate`, {
        sharedResource: { sharedResourceId },
        useExistingDependencies: options.useExistingDependencies || false,
        createdById: 'current',
    });

    return data;
};

export const exportRequestStatus = async ({ requestId }) => {
    const { data } = await axios.get(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/exportRequests/${requestId}`);

    return data;
};

export const createBundle = async ({
    title, description, tenantId, dependencies, topLevel,
}) => {
    const { data } = await axios.post(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/bundles`, {
        tenantId,
        title,
        description,
        createdById: 'current',
        topLevel: topLevel || false,
        dependencies: dependencies.map((i) => ({
            tenantId,
            resourceType: i.resourceType,
            sourceTenantId: i.sourceTenantId,
            sourceResourceId: i.resourceId,
            sharedResourceId: i.sharedResourceId,
            title: i.title,
            createdById: 'current',
        })),
    });

    return data;
};

export const deleteSharedResource = async ({ sharedResourceId }) => {
    const { data } = await axios.post(`${window.__KEAP__.VUE_APP_PACKAGING_SERVICE_URL}/api/v2/sharedResources/${sharedResourceId}:softDelete`, {
        deletedById: 'current',
    });

    return data;
};
